import {
    createBrowserRouter
} from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES    

import Login from '../components/authentication/login'
import ForgotPassword from '../components/authentication/forgotPassword'
import CheckEmail from '../components/authentication/checkEmail';
import CodeVerification from '../components/authentication/codeVerification';
import ResetPassword from '../components/authentication/resetPassword';
import Error404Page from '../components/error/Error404Page'


// CORES
import Dashboard from '../components/resident/dashboard/dashboard';
import Invoices from '../components/resident/statements/invoices'
import Receipts from '../components/resident/statements/receipts'
import PaymentHistory from '../components/resident/statements/paymentHistory'
import StatementofAccount from '../components/resident/statements/statementofAccount'
import SelectWaterMeter from '../components/resident/utilities/water/select_meter'
import WaterMeterInfo from '../components/resident/utilities/water/meter_info'
import SelectPowerMeter from '../components/resident/utilities/power/select_meter'
import PowerMeterInfo from '../components/resident/utilities/power/meter_info'
import ViewTickets from '../components/resident/tickets/view_tickets';
import RaiseTickets from '../components/resident/tickets/raise_ticket';
import Feedback from '../components/resident/feedback/feedback';
import ResidentWall from '../components/resident/resident_wall/resident_wall'
import Family from '../components/resident/visitor_access_management/family';
import AddFamily from '../components/resident/visitor_access_management/add_family'
import Vehicle from '../components/resident/visitor_access_management/vehicles';
import AddVehicle from '../components/resident/visitor_access_management/add_vehicle'
import Staff from '../components/resident/visitor_access_management/staff';
import AddStaff from '../components/resident/visitor_access_management/add_staff'
import Visitors from '../components/resident/visitor_access_management/visitors';
import AddVisitor from '../components/resident/visitor_access_management/add_visitor'
import VisitationHistory from '../components/resident/visitor_access_management/digital_logs'
import VisitDigitalLog from '../components/resident/visitor_access_management/view_digital_log'
import Setting from '../components/resident/settings/setting'
import ViewDigitalLogQuickLink from '../components/resident/visitor_access_management/view_digital_log_quick_link'

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/forgot_password",
        element: <ForgotPassword />,
    },
    {
        path: "/check_email",
        element: <CheckEmail />,
    },
    {
        path: "/code_verification",
        element: <CodeVerification />,
    },
    {
        path: "/reset_password/:userId",
        element: <ResetPassword />,
    },
    {
        path: "/resident/",
        element: <Dashboard/>,
    },
    {
        path: "/resident/invoices",
        element: <Invoices/>,
    },
    {
        path: "/resident/receipts",
        element: <Receipts/>,
    },
    {
        path: "/resident/payment_history",
        element: <PaymentHistory/>,
    },
    {
        path: "/resident/statement_of_account",
        element: <StatementofAccount/>,
    },
    {
        path: "/resident/water_utility",
        element: <SelectWaterMeter/>,
    },
    {
        path: "/resident/water_utility/meter_info",
        element: <WaterMeterInfo/>,
    },
    {
        path: "/resident/power_utility",
        element: <SelectPowerMeter/>,
    },
    {
        path: "/resident/power_utility/meter_info",
        element: <PowerMeterInfo/>,
    },
    {
        path: "/resident/view_tickets",
        element: <ViewTickets/>,
    },
    {
        path: "/resident/raise_ticket",
        element: <RaiseTickets/>,
    },
    {
        path: "/resident/feedback",
        element: <Feedback/>,
    },
    {
        path: "/resident/resident_wall",
        element: <ResidentWall/>,
    },
    {
        path: "/resident/visitor_access/family",
        element: <Family/>,
    },
    {
        path: "/resident/visitor_access/add_family",
        element: <AddFamily/>,
    },
    {
        path: "/resident/visitor_access/vehicles",
        element: <Vehicle/>,
    },
    {
        path: "/resident/visitor_access/add_vehicle",
        element: <AddVehicle/>,
    },
    {
        path: "/resident/visitor_access/staff",
        element: <Staff/>,
    },
    {
        path: "/resident/visitor_access/add_staff",
        element: <AddStaff/>,
    },
    {
        path: "/resident/visitor_access/visitors",
        element: <Visitors/>,
    },
    {
        path: "/resident/visitor_access/add_visitor",
        element: <AddVisitor/>,
    },
    {
        path: "/resident/visitor_access/digital_logs",
        element: <VisitationHistory/>,
    },
    {
        path:"/resident/visitor_access/view_digital_log/:visitLogId",
        element:<VisitDigitalLog/>

    },
    {
        path:"/va/dlql/:visitLogId",
        element:<ViewDigitalLogQuickLink/>

    },
    {
        path: "/resident/settings",
        element: <Setting/>,
    },

    
    {
        path: "*",
        element: <Error404Page />,
    },
]);