
import React, { useState, useEffect ,useRef} from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import QRCode  from "react-qr-code";
import { toPng } from "html-to-image";
import download from "downloadjs";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
import { toastify } from '../../../utils/toast'
import { makeRequest2 } from '../../../utils/makeRequest'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { getStaffURL, deleteStaffURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage';
function Staff() {
  const qrCodeRef = useRef();
  const navigate = useNavigate()
  const [staff, setStaff] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQRCode, setSelectedQRCode] = useState({})
  const [visibleQRCode, setVisibleQRCode] = useState(false)
  const [customerId,setCustomerId] = useState('')
  const [customerNo, setCustomerNo] = useState("")
  const qrCodeTemplate = (rowData) => {
    rowData.type = 'Staff'
    rowData.customerId = customerId
    if (rowData.qrCode === undefined) {
      return `....`
    }
    else {
      if (rowData.qrCode) {
        return <button className="btn btn-dark btn-sm" onClick={
          () => {
            setSelectedQRCode(rowData)
            setVisibleQRCode(true)
          }
        }>QR Code</button>
      }
      else {
        return `....`
      }
    }
  }
  const getCustomerNo = async () => {
    try {
      const customerNo = await getItem('selectedCustomerNo')
      setCustomerNo(customerNo)
    }
    catch (err) {
      console.log(err.message)
    }
  }
  const handleDownloadQRCode = () => {
    if (qrCodeRef.current) {
      console.log(qrCodeRef.current)
      toPng(qrCodeRef.current)
        .then((dataUrl) => {

          download(dataUrl, "qrcode.png");
        })
        .catch((err) => {
          console.error("Oops, something went wrong!", err);
        });
    }
  };
  const fetchStaffMembers = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(getStaffURL + '/' + CustomerId, 'GET', {})

      if (response.success) {
        setStaff(response.data.staff)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      throw new Error(err.message)
    }
  }
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchStaffMembers()
      } else {
        const results = await filterObjectsByAllProperties(staff, value);
        setStaff(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };

  const actionTemplate = (rowData) => {

    return (
      <div className="actions">

        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            handleDelete(rowData._id);

          }}
          tooltip="Delete"
        // Allow deletion only if the row is disabled
        />

      </div>



    );
  };
  const handleDelete = async (staffId) => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(deleteStaffURL + '/' + CustomerId + '/' + staffId, 'DELETE');
      if (response.success) {
        toastify(response.data, 'success');
        fetchStaffMembers();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };
  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }

  const getCustomerId = async ()=>{
    const cID = await getItem('selectedCustomerId');
    setCustomerId(cID)
  }
  useEffect(() => {
    fetchStaffMembers()
    getCustomerId()
    getCustomerNo()
  }, [])
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/staff"}>Staff </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => { handleInputSearch(e.target.value) }}
                      />
                    </div>
                    <div className="col-md-9 col-xs-12 mt-3" >
                      <div style={{ float: 'right' }}>
                        <br />
                        <button className="btn btn-primary" onClick={() => {
                          navigate('/resident/visitor_access/add_staff')
                        }}><i className="ti ti-plus pt-2"></i> &nbsp;Add Staff</button>
                      </div>

                    </div>


                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={staff}  emptyMessage="No staff found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                      <Column field="name" header="Name"></Column>
                      <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                      <Column field="jobRole" header="Job Role"></Column>
                      <Column body={qrCodeTemplate} header="QRCode" />
                     
                      <Column body={actionTemplate} header="Actions"></Column>

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog
        header={'QR Code'}
        visible={visibleQRCode}
        onHide={() => {
          if (visibleQRCode) setVisibleQRCode(false);
        }}
      >
        <div className="text-center mt-4">
          <div ref={qrCodeRef}>
            <QRCode value={JSON.stringify(selectedQRCode)} />
            <br />
            <br />
            <p style={{ color: 'white', backgroundColor: 'black' }}>{customerNo}</p>
            <p style={{color:'white',backgroundColor:'black'}}>POWERED BY PAYSERVE.CO.kE</p>
          </div>
          <br />
          <button className="btn btn-primary mt-3" onClick={handleDownloadQRCode}>
            Download QR Code
          </button>
        </div>


      </Dialog>

    </Layout>
  )
}


export default Staff