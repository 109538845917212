import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { updateSpinner } from '../../features/authentication/authenticationReducer';
import { makeRequest } from '../../utils/makeRequest';
import { toastify } from '../../utils/toast';
import { setItem, getItem } from '../../utils/localStorage';
import { loginURL, resendCodeURL, verifyOTPURL, codeVerificationURL } from '../../utils/urls';
import { transformEmail } from '../../utils/transformEmail';

function CodeVerification() {

  const [codeInputs, setCodeInputs] = useState(['', '', '', '', '']);
  const codeSent = useRef(false);  // Track if the code has been sent
  const navigate = useNavigate();
  const loginEmail = useSelector((state) => state.authenticationReducer.loginEmail);
  const loginPassword = useSelector((state) => state.authenticationReducer.loginPassword);
  const dispatch = useDispatch();

  const inputRefs = useRef([]);

  const resendCode = async () => {
    try {
      dispatch(updateSpinner(true));
      const body = { email: loginEmail };
      const response = await makeRequest(resendCodeURL, 'POST', body);
      if (response.success) {
        dispatch(updateSpinner(false));
        sentNotificationCode()

      }
      else {
        throw new Error('Failed to resend code')
      }

    } catch (err) {
      toastify(err.message, 'error');
      dispatch(updateSpinner(false));
    }
  };

  const sentNotificationCode = async () => {
    try {
      dispatch(updateSpinner(true));
      const body = { email: loginEmail };
      const response = await makeRequest(codeVerificationURL, 'POST', body);
      if (response.success) {
        dispatch(updateSpinner(false));
      

      }
      else {
        throw new Error('Failed to send code')
      }
    } catch (err) {
      toastify(err.message, 'error');
      dispatch(updateSpinner(false))
    }
  }

  const verifyCode = async () => {
    dispatch(updateSpinner(true));
    try {
      const code = codeInputs.join('');
      const response = await makeRequest(verifyOTPURL, 'POST', {
        code, email: loginEmail
      });
      if (response.success) {
        const body = { email: loginEmail, password: loginPassword };
        const response = await makeRequest(loginURL, 'POST', body);
        if (response.success) {
          dispatch(updateSpinner(false));
          await setItem('RESIDENTUSER', JSON.stringify(response.data));
          setTimeout(() => {
            navigate('/resident')
          }, 1000)

        } else {
          toastify(response.error, 'error');
          dispatch(updateSpinner(false));
        }
      }
      else {
        throw new Error(response.error)
      }
    } catch (err) {
      toastify(err.message, 'error');
      dispatch(updateSpinner(false));
    }
  };

  const handleInputChange = (index) => (e) => {
    const value = e.target.value;
    if (value.length === 1 && value >= 0 && value <= 9) {
      const newCodeInputs = [...codeInputs];
      newCodeInputs[index] = value;
      setCodeInputs(newCodeInputs);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0) {
      const newCodeInputs = [...codeInputs];
      newCodeInputs[index] = '';
      setCodeInputs(newCodeInputs);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };


  const onLoadFunction = async () => {
    dispatch(updateSpinner(true));
    const userExist = await getItem('RESIDENTUSER');

    if (userExist !== null) {
      navigate('/resident')
    }
    else {
      if (!codeSent.current) {
        sentNotificationCode()
        codeSent.current = true;
      }
    }
  }
  useEffect(() => {
    onLoadFunction()

  }, []);

  return (
    <div className="auth-main">
      <div className="auth-wrapper v2">
        <div className="auth-sidecontent">
          <img src="/assets/images/authentication/img-auth-sideimg.jpg" alt="images" className="img-fluid img-auth-side" />
        </div>
        <div className="auth-form">
          <div className="card my-5">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"#"}><img src="/assets/images/PayServeLogoFinal.png" alt="img" style={{ width: 300 }} /></Link>
                <h3 className="mb-2"><b>Enter Verification Code</b></h3>
                <p className="text-muted mb-4">We sent you a code to: <strong>{transformEmail(loginEmail)}</strong></p>
              </div>
              <div className="row my-4 text-center">
                {codeInputs.map((input, index) => (
                  <div className="col" key={index}>
                    <input
                      type="number"
                      className="form-control text-center code-input"
                      placeholder="0"
                      min="0"
                      max="9"
                      value={codeInputs[index]}
                      onChange={handleInputChange(index)}
                      ref={(el) => inputRefs.current[index] = el}
                    />
                  </div>
                ))}
              </div>
              <div className="d-grid mt-4">
                <button type="button" className="btn btn-primary" onClick={verifyCode}>Continue</button>
              </div>
              <div className="d-flex justify-content-start align-items-end mt-3">
                <p className="mb-0">Did not receive the email?</p>
                <Link to={'#'} onClick={resendCode} className="link-primary ms-2">
                  Resend Code
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodeVerification;
