
export const backend_url= 'https://api.payserve.co.ke'
export const checkEmailAndPasswordURL = '/api/auth/check_email_and_password'
export const loginURL = '/api/auth/login'
export const forgotPasswordURL = '/api/auth/forgot_password'
export const resetPasswordURL = '/api/auth/reset_password'
export const codeVerificationURL = '/api/auth/otp'
export const resendCodeURL = '/api/auth/resend_code'
export const verifyOTPURL = '/api/auth/verify_otp'
export const checkTokenExpirationURL = '/api/auth/check_jwt_expiration'
export const residentUnitsURL = '/api/resident/dashboard/get_units'
export const residentFacilitiessURL = '/api/resident/dashboard/get_facilities'
export const addFamilyMemberURL = '/api/resident/visitor_access/family/add_family_member'
export const deleteFamilyMemberURL = '/api/resident/visitor_access/family/delete_family_member'
export const getFamilyMembersURL = '/api/resident/visitor_access/family/get_family_members'
export const addVehicleURL = '/api/resident/visitor_access/vehicle/add_vehicle'
export const deleteVehicleURL = '/api/resident/visitor_access/vehicle/delete_vehicle'
export const getVehiclesURL = '/api/resident/visitor_access/vehicle/get_vehicles'
export const addStaffURL = '/api/resident/visitor_access/staff/add_staff'
export const deleteStaffURL = '/api/resident/visitor_access/staff/delete_staff'
export const getStaffURL = '/api/resident/visitor_access/staff/get_staffs'
export const addVisitorURL = '/api/resident/visitor_access/visitor/add_visitor'
export const inviteVisitorURL = '/api/resident/visitor_access/visitor/invite_visitor'
export const deleteVisitorURL = '/api/resident/visitor_access/visitor/delete_visitor'
export const getVisitorsURL = '/api/resident/visitor_access/visitor/get_visitors'
export const getVisitLogsURL = '/api/resident/visitor_access/logs/get_visit_logs'
export const getVisitLogURL = '/api/resident/visitor_access/logs/get_visit_log'
export const acceptVisitRequestURL = '/api/resident/visitor_access/logs/accept_visit'
export const denyVisitRequestURL = '/api/resident/visitor_access/logs/deny_visit'
export const getVisitLogQLURL = '/api/resident/visitor_access/logs/get_visit_log_ql'
export const acceptVisitRequestQLURL = '/api/resident/visitor_access/logs/accept_visit_ql'
export const denyVisitRequestQLURL = '/api/resident/visitor_access/logs/deny_visit_ql'