
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';

import { Dialog } from 'primereact/dialog';
import { deleteVisitorURL, getVisitorsURL, residentUnitsURL, inviteVisitorURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import { addDaysToDate } from '../../../utils/addDaysToDate';

function Visitors() {
  const navigate = useNavigate()
  const [visitors, setVisitors] = useState([]);
  const [inviteDialogVisible, setInviteDialogVisible] = useState(false)
  const [units, setUnits] = useState([]);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [visitingDate, setVisitingDate] = useState('');
  const [visitingDays, setVisitingDays] = useState(0);
  const [visitingEndDate, setVisitingEndDate] = useState('');
  const [unit, setUnit] = useState('');
  const [searchTerm, setSearchTerm] = useState('');


  const fetchVisitors = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(getVisitorsURL + '/' + CustomerId, 'GET', {});

      if (response.success) {
        setVisitors(response.data.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error')
    }
  };
  const fetchResidentUnits = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const FacilityId = await getItem('selectedFacilityId');

      const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
      if (response.success) {
        setUnits(response.data)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const handleDeleteVisitor = async (visitorId) => {
    try {
      const response = await makeRequest2(`${deleteVisitorURL}/${visitorId}`, 'DELETE', {});
      if (response.success) {
        toastify('Visitor deleted successfully', 'success');
        fetchVisitors()
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchVisitors();
      } else {
        const results = await filterObjectsByAllProperties(visitors, value);
        setVisitors(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };
  const handleSubmitClick = async () => {
    try {
      if (visitingDate === '') {
        throw new Error('Visiting Date is required')
      }
      else if (visitingDays < 0) {
        throw new Error('Visiting Days should be greater or equal to 0.')
      }


      else if (unit === '') {
        throw new Error('Unit is required')
      }
      else {
        const CustomerId = await getItem('selectedCustomerId');
        const FacilityId = await getItem('selectedFacilityId');
        const response = await makeRequest2(inviteVisitorURL + '/' + CustomerId + '/' + FacilityId, 'POST', {
          visitingDate, visitingDays, visitingEndDate, unit, selectedVisitor
        })
        if (response.success) {
          toastify('Invite sent successfully', 'success')
          setInviteDialogVisible(false);
          setVisitingDate('')
          setVisitingDays(0)
          setVisitingEndDate('')
          setUnit('')

          return
        }
        else {
          throw new Error(response.error)
        }
      }


    }
    catch (err) {
      toastify(err.message, 'error')
      return
    }
  }
  const handleActionClick = (rowData) => {
    setSelectedVisitor(rowData);
    setInviteDialogVisible(true);
    setVisitingDate('')
    setVisitingDays(0)
    setVisitingEndDate('')
    setUnit('')
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="ti ti-row-insert-top"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => { handleActionClick(rowData) }}
          tooltip="Invite"
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            handleDeleteVisitor(rowData._id)

          }}
          tooltip="Delete"

        />
      </div>
      // <React.Fragment>
      //     <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" onClick={() => handleActionClick(rowData)} />
      // </React.Fragment>
    );
  };
  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }


  useEffect(() => {
    fetchVisitors();
    fetchResidentUnits()

  }, []);
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/visitors"}>Visitors</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => { handleInputSearch(e.target.value) }}
                      />
                    </div>
                    <div className="col-md-9 col-xs-12 mt-3" >
                      <div style={{ float: 'right' }}>
                        <br />
                        <button className="btn btn-primary" onClick={() => { navigate('/resident/visitor_access/add_visitor') }}><i className="ti ti-plus pt-2"></i> &nbsp;Add Visitor</button>
                      </div>

                    </div>


                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={visitors} emptyMessage="No visitors found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>


                      <Column header="Visitor Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}></Column>

                      <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                      <Column field={'email'} header="Email"></Column>
                      <Column field="idNumber" header="ID No"></Column>
                      <Column body={actionBodyTemplate} header="Action"></Column>

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog
        header={'Invite'}
        visible={inviteDialogVisible}
        style={{ width: '30vw' }}
        onHide={() => {
          if (inviteDialogVisible) setInviteDialogVisible(false);
        }}
      >

        <div className="row">
          <div className="col-md-12 mt-3">


            <div className="row">
              <div className="col-sm-12">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          selectedVisitor &&
                          <div className="card">
                            <div className="card-body">
                              <p><strong>Full Name:</strong> {selectedVisitor.firstName} {selectedVisitor.lastName}</p>
                              <p><strong>Phone Number:</strong> {selectedVisitor.phoneNumber}</p>
                              <p><strong>ID Number:</strong> {selectedVisitor.idNumber}</p>
                            </div>

                          </div>
                        }


                      </div>

                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Visiting Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={visitingDate}
                            onChange={(e) => setVisitingDate(e.target.value)}
                            min={new Date().toISOString().split("T")[0]} // Set min to today's date
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Visiting Days</label>
                          <input type="number" className="form-control" value={visitingDays} onChange={async (e) => {
                            if(parseInt(e.target.value) < 0){
                               toastify('Number should be greater than 0','error')
                            }
                            else
                            {
                              setVisitingDays(e.target.value)
                              const endDate = await addDaysToDate(parseInt(e.target.value), visitingDate)
                              setVisitingEndDate(endDate)
                            }
                            
                          }} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Visit End Date: <strong style={{color:'red'}}>{visitingEndDate !== '' && visitingEndDate}</strong></label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Unit</label>
                          <select className="form-control" value={unit} onChange={(e) => {
                            setUnit(e.target.value)
                          }}>
                            <option value="">-- Select --</option>
                            {
                              units.map((unit) => {
                                return <option value={unit.name}>{unit.name}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>
              </div>
            </div>





            <div className='mt-4' style={{ float: 'right' }}>
              <button className="btn btn-primary"
                onClick={handleSubmitClick}
              >
                Submit
              </button>
            </div>

          </div>

        </div>



      </Dialog>

    </Layout>
  )
}


export default Visitors